<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Preferences"/>

    <div class="mainBox mainBox60 border shadow-sm"> 
        <ul class="nav nav-pills">
            <li class="nav-item">
                <a class="nav-link" :class="route.params.packageId === undefined && !selectBillTab ? 'active' : ''" data-bs-toggle="pill" href="#personal">Personal Information</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#password">Change Password</a>
            </li>
            <li v-if="getUserIsVerified === 'false' || getUserIsVerified === false" class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#kyc">KYC Information</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#sign">Signature</a>
            </li>
            <!-- <li class="nav-item active" :class="route.params.packageId === 'personal' || route.params.packageId === 'team'  ? 'active' : ''"> -->
            <li class="nav-item">
                <a class="nav-link" :class="route.params.packageId !== undefined || selectBillTab ? 'active' : ''" data-bs-toggle="pill" href="#billing">Billing & Subscription</a>
            </li>
        </ul>

        <div class="tab-content mt-4">
            <div class="tab-pane container" :class="route.params.packageId === undefined && !selectBillTab ? 'active' : ''" id="personal">
                <div class="row">

                    <div class="col-12 pb-3 text-center">
                        <table border="0">
                            <tr>
                                <td class="pe-2">
                                    <div class="d-flex justify-content-center text-center" style="width: 120px; height: 120px">
                                        <!-- <img v-if="avatar && avatar.url" :src="avatar.url" class="rounded-circle" width="120" height="120" align="center" style="position:absolute;" />  -->
                                        <!-- <img v-if="avatar && avatar.url" :src="avatar.url" class="rounded-circle" align="center" style="position:absolute; max-width: 120px; max-height: 120px; width: auto: height: auto" />  -->
                                        <img v-if="avatar && avatar.url" :src="avatar.url" class="rounded-circle" align="center" style="position:absolute; width: 120px; height: 120px; object-fit: cover" /> 
                                        <fa v-else icon="circle-user" style="position:absolute; width: 120px; height: 120px;" />
                                        <div class="customLink rounded-circle isLink" data-bs-toggle="modal" data-bs-target="#mdlAvatar">
                                            <fa icon="camera" class="text-secondary" style="font-size: 32px;"/>
                                        </div>
                                        <span v-if="getUserIsVerified === 'true'" id="certificate" style="z-index:1000; font-size: 25px; margin-top: 82px; margin-left: 98px; position:absolute;">
                                            <fa icon="certificate" style="color: #53BDEB" />
                                            <div id="lblCertificate">Verified</div>
                                        </span>    

                                    </div>
                                </td>
                                <td class="text-start">
                                    <div style="font-size: 16px">{{displayName}}</div>
                                    <div>{{email}}</div>
                                    <div><br /></div>
                                    <div>joined {{func.convDateTimeFormat(dateCreate, 'date')}} <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> last login {{ func.getDateTimeDiff(func.convDateTimeFormat(dateLastLogin), new Date()) }}</div>
                                    <div>{{plan}}</div>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="col-12 col-lg-6 pb-3">
                        <input v-model="firstName" type="text" class="form-control" placeholder="First name">
                    </div>

                    <div class="col-12 col-lg-6 pb-3">
                        <input v-model="lastName" type="text" class="form-control" placeholder="Last name">
                    </div>

                    <div class="col-12 col-lg-6 pb-3">
                        <select v-model="title" class="form-select">
                            <option value="null">Select title</option>
                            <option :value="t.value" v-for="t in titleOpt" :key="t.value">{{ t.label }}</option>
                        </select>
                    </div>

                    <div class="col-12 col-lg-6 pb-3">
                        <input v-model="displayName" type="text" class="form-control" placeholder="Display name">
                    </div>

                    <div class="col-12 col-lg-6 pb-3">
                        <select v-model="gender" class="form-select">
                            <option value="null">Select gender</option>
                            <option :value="g.value" v-for="g in genderOpt" :key="g.value">{{g.label}}</option>
                        </select>
                    </div>

                    <div class="col-12 col-lg-6 pb-3">
                        <select v-model="gmt" class="form-select">
                            <option value="null">Select GMT</option>
                            <option :value="g.value" v-for="g in gmtOpt" :key="g.value">{{g.label}}</option>
                        </select>
                    </div>

                    <div class="col-12 pt-3 text-center">
                        <button class="btn btn-secondary" @click="updProfile">
                            <fa icon="rotate" size="lg" class="me-2" />Update
                        </button>
                    </div>

                </div><!-- row -->
            </div>

            <div class="tab-pane container fade" id="password">
                <div class="row">
                    <div class="col-12 col-lg-6 pb-3">
                        <input v-model="oldPassword" type="password" class="form-control" placeholder="Current password">
                    </div>

                    <div class="col-12 col-lg-6 d-none d-md-block">
                        
                    </div>

                    <div class="col-12 col-lg-6 pb-3">
                        <input v-model="password" type="password" class="form-control" placeholder="New password">
                    </div>

                    <div class="col-12 col-lg-6 pb-3">
                        <input v-model="password2" type="password" class="form-control" placeholder="Reconfirm new password">
                    </div>

                    <div class="col-12 pt-3 pb-0 text-center">
                        <button v-if="getUserStatus === 'ACTIVE'" class="btn btn-secondary" @click="updPassword">
                            <fa icon="rotate" size="lg" class="me-2" />Update
                        </button>
                        <button v-else class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlUpdProfile">
                            <fa icon="rotate" size="lg" class="me-2" />Update
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="getUserIsVerified === 'false' || getUserIsVerified === false" class="tab-pane container fade" id="kyc">
                <div class="row">
                    <div class="col-12 pb-3">Complete KYC verification by upload front and back of your NRIC/Passport to have more access in the system</div>
                    <div class="col-12">
                        <file-pond
                            name="file2"
                            ref="pondKyc"
                            label-idle="Drag-n-drop images"
                            v-bind:allow-multiple="true"
                            accepted-file-types="image/jpg, image/jpeg, image/png, image/gif"
                            :server="kycConfig"
                            :files="myKyc"
                            storeAsFile="true"
                            @init="handleKycUploadInit"
                            @error="uplKycError"
                            @addfile="getKyc"
                            allowRevert="true"
                            allowRemove="false"
                        />
                        <div class="text-center small text-grey">Multiple images <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB/image limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div>
                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Please ensure all 4 corners are visible</div>
                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Text is clerly readable without blockage or blurry</div>
                        <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Images in original color, not black & white or changed in any way</div>

                    </div>
                </div>
            </div>

            <div class="tab-pane container fade" id="sign">
                <div class="row">
                    <div class="col-12 pb-3">Upload signature / initial to simplify your signing process </div>
                    <div class="col-12 col-lg-6">
                        <file-pond
                            name="file"
                            ref="pondS"
                            label-idle="Drag-n-drop signature"
                            v-bind:allow-multiple="true"
                            accepted-file-types="image/jpeg, image/png, image/gif"
                            :server="signatureConfig"
                            :files="mySignatures"
                            storeAsFile="true"
                            @init="handleSignaturePondInit"
                            @error="uplSignatureError"
                            @processfile="onprocessSignature"
                            @addfile="getSignature"
                            allowRevert="true"
                            allowRemove="false"
                        />
                        <!-- <div class="text-center small text-grey">Multiple images <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB/image limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div> -->
                    </div>

                    <div class="col-12 col-lg-6">
                        <file-pond
                            name="file"
                            ref="pondI"
                            label-idle="Drag-n-drop initial"
                            v-bind:allow-multiple="true"
                            accepted-file-types="image/jpeg, image/png, image/gif,"
                            :server="initialConfig" 
                            :files="myInitials"
                            storeAsFile="true"
                            @init="handleInitialPondInit"
                            @error="uplInitialError"
                            @processfile="onprocessInitial"
                            allowRevert="true"
                            allowRemove="false"
                        />
                        <!-- <div class="text-center small text-grey">Multiple images <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB/image limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div> -->
                    </div>

                    <div class="col-12 text-center small text-grey pb-5">
                        Multiple images <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB/image limit 
                        <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only
                    </div>

                    <div v-if="arrSig && arrSig.length > 0" class="col-12 col-lg-6">
                        <div class="fw-bold mb-3">Saved Signature</div>
                        <div>
                            <div v-for="(s, sIdx) in arrSig.slice(0, 2)" :key="s.signatureId">
                                <table class="table table-borderless isLink">
                                    <tr>
                                        <td>
                                            <img :src="'data:image/png;base64, ' + s.imgBase64" style="width: auto; maxHeight: 140px; padding: 0px;"/>
                                        </td>
                                        <td class="text-end" width="40px">
                                            <Popper class="popperDark" arrow hover content="Delete signature">
                                                <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'signature')"/>
                                            </Popper>
                                        </td>
                                    </tr>
                                </table>

                                <hr v-if="sIdx < arrSig.length-1" />
                            </div>
                            
                            <div v-if="showMoreSig" v-for="(s, sIdx) in arrSig.slice(2)" :key="s.signatureId">
                                <table class="table table-borderless isLink">
                                    <tr>
                                        <td>
                                            <img :src="'data:image/png;base64, ' + s.imgBase64" style="width: auto; maxHeight: 140px; padding: 0px;"/>
                                        </td>
                                        <td class="text-end" width="40px">
                                            <Popper class="popperDark" arrow hover content="Delete signature">
                                                <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'signature')"/>
                                            </Popper>
                                        </td>
                                    </tr>
                                </table>

                                <hr v-if="sIdx < arrSig.length-1" />
                            </div>

                            <div v-if="arrSig.length > 2" class="text-end text-primary">
                                <span @click="showMoreSig = !showMoreSig" class="isLink">
                                    <span v-if="!showMoreSig"><fa icon="angle-down" /> more</span>
                                    <span v-if="showMoreSig"><fa icon="angle-up" /> less</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div v-if="arrIni && arrIni.length > 0" class="col-12 col-lg-6">
                        <div class="fw-bold mb-3">Saved Initial</div>
                        <div >
                            <div v-for="(i, iIdx) in arrIni.slice(0, 2)" :key="i.initalId">
                                <table class="table table-borderless isLink">
                                    <tr>
                                        <td>
                                            <img :src="'data:image/png;base64, ' + i.imgBase64" style="width: auto; maxHeight: 140px; padding: 0px;"/>
                                        </td>
                                        <td class="text-end" width="40px">
                                            <Popper class="popperDark" arrow hover content="Delete signature">
                                                <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(i.signatureId, 'initial')"/>
                                            </Popper>
                                        </td>
                                    </tr>
                                </table>

                                <hr v-if="iIdx < arrIni.length-1" />
                            </div>
                            
                            <div v-if="showMoreIni" v-for="(i, iIdx) in arrIni.slice(2)" :key="i.initialId">
                                <table class="table table-borderless isLink">
                                    <tr>
                                        <td>
                                            <img :src="'data:image/png;base64, ' + i.imgBase64" style="width: auto; maxHeight: 140px; padding: 0px;"/>
                                        </td>
                                        <td class="text-end" width="40px">
                                            <Popper class="popperDark" arrow hover content="Delete initial">
                                                <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(i.signatureId, 'initial')"/>
                                            </Popper>
                                        </td>
                                    </tr>
                                </table>

                                <hr v-if="sIdx < arrSig.length-1" />
                            </div>

                            <div v-if="arrIni.length > 2" class="text-end text-primary">
                                <span @click="showMoreIni = !showMoreIni" class="isLink">
                                    <span v-if="!showMoreIni"><fa icon="angle-down" /> more</span>
                                    <span v-if="showMoreIni"><fa icon="angle-up" /> less</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane container" :class="route.params.packageId !== undefined || selectBillTab ? 'active' : 'fade'" id="billing">

                <div v-if="upgradeStep === 0" class="row">
                    <div class="col-12 col-md-6 col-lg-4 d-grid">
                        <div class="packageBox border shadow-sm">
                            <div class="fw-bold text-center mb-1">
                                <span>Personal</span>
                            </div>
                            <div class="text-center">
                                <span class="fw-bold text-uppercase">MYR</span>
                                <span class="fs-1 ps-1">200</span>
                                <span> / year</span>
                            </div>
                            <div class="text-center"><hr /></div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">Single</span> user
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">Unlimited</span> documents
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/>
                                <span>    
                                    <span class="fw-bold"><span class="text-uppercase">MYR</span>1</span> per signee
                                </span>
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">1GB</span> storage rack
                            </div>
                            <div class="text-center pt-4">
                                <!-- <button class="btn btn-secondary" @click="selectPackage('personal')"> -->
                                <button class="btn btn-secondary" @click="$router.push({ path: '/auth/preference/upgrade/personal' })">
                                    <fa icon="arrow-trend-up" class="me-2" />Upgrade
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 d-grid">
                        <div class="packageBox border shadow-sm">
                            <div class="fw-bold text-center mb-1">
                                <span>Team</span>
                            </div>
                            <div class="text-center">
                                <span class="fw-bold text-uppercase">MYR</span>
                                <span class="fs-1 ps-1">360</span>
                                <span> / year</span>
                            </div>
                            <div class="text-center"><hr /></div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">200</span> users
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">Unlimited</span> documents
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/>
                                <span>    
                                    <span class="fw-bold">Unlimited</span> signees
                                </span>
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/>Send <span class="fw-bold">invitations</span>
                            </div>
                            <div class="text-center pt-4">
                                <button class="btn btn-secondary" @click="$router.push({ path: '/auth/preference/upgrade/team' })">
                                <!-- <button class="btn btn-secondary" @click="selectPackage('team')"> -->
                                    <fa icon="arrow-trend-up" class="me-2" />Upgrade
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="upgradeStep === 1" class="row">
                    <div class="col-12 mb-4">
                        <UpgradeStep :step="upgradeStep" />
                    </div>

                    <div v-if="!isValidPassword" class="col-12 mb-2">Perform authority check by enter your password prior to package upgrade</div>
                    <div v-if="!isValidPassword" class="col-12 col-lg-6 mb-4">
                        <div class="input-group">
                            <input type="password" v-model="upgradePassword" class="form-control" placeholder="Password" :readonly="isValidPassword" />
                            <button class="btn btn-secondary" @click="validatePassword()" :disabled="isValidPassword">Submit</button>
                        </div>
                    </div>

                    <div v-if="isValidPassword" class="col-12 mb-2">TAC will send to email below</div>
                    <div v-if="isValidPassword" class="col-12 col-lg-6 mb-3">
                        <div class="input-group">
                            <input type="email" v-model="upgradeEmail" class="form-control" readonly />
                            <button class="btn btn-secondary" @click="sendUpgradeEmail()">Next</button>
                        </div>
                    </div>
                </div>

                <div v-if="upgradeStep === 2" class="row">
                    <div class="col-12 mb-4">
                        <UpgradeStep :step="upgradeStep" />
                    </div>

                    <div class="col-12 mb-2">Enter the TAC in your registered email ({{upgradeEmail}})</div>

                    <div class="col-12 col-lg-6 mb-3">
                        <div class="input-group">
                            <input type="text" v-model="upgradeTac" class="form-control" />
                            <button class="btn btn-secondary" @click="verifyUpgradeTac()">Verify</button>
                        </div>
                    </div>

                </div>

                <div v-if="upgradeStep === 3" class="row">
                    <div class="col-12 mb-4">
                        <UpgradeStep :step="upgradeStep" />
                    </div>

                    <div v-if="!isPaymentSuccess" class="col-12 mb-2">You will upgrade to package below</div>
                    <div v-if="!isPaymentSuccess && route.params.packageId === 'personal'" class="col-12 col-lg-6 mb-3">
                        <div class="packageBox border shadow-sm" style="width: 265px;">
                            <div class="fw-bold text-center mb-1" >
                                <span>Personal</span>
                            </div>
                            <div class="text-center">
                                <span class="fw-bold text-uppercase">MYR</span>
                                <span class="fs-1 ps-1">200</span>
                                <span> / year</span>
                            </div>
                            <div class="text-center"><hr /></div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">Single</span> user
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">Unlimited</span> documents
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/>
                                <span>    
                                    <span class="fw-bold"><span class="text-uppercase">MYR</span>1</span> per signee
                                </span>
                            </div>
                            <div>
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">1GB</span> storage rack
                            </div>
                        </div>
                    </div>
                    <div v-if="!isPaymentSuccess && route.params.packageId === 'personal'" class="col-12 col-lg-6 mb-3 text-center">
                        <div class="mb-3">Click this button will redirect you to payment page</div>
                        <div class="text-center">
                            <button class="btn btn-secondary" @click="makePayment">
                                <fa icon="dollar-sign" class="me-2" />Make payment
                            </button>
                        </div>
                    </div>

                    <div v-if="!isPaymentSuccess && route.params.packageId === 'team'" class="col-12 col-lg-6 mb-3">
                        <div class="packageBox border shadow-sm" style="width: 265px;">
                            <div class="fw-bold text-center mb-1">
                                <span>Team</span>
                            </div>
                            <div class="text-center">
                                <span class="fw-bold text-uppercase">MYR</span>
                                <span class="fs-1 ps-1">360</span>
                                <span> / year</span>
                            </div>
                            <div class="text-center"><hr /></div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">200</span> users
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">Unlimited</span> documents
                            </div>
                            <div class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/>
                                <span>    
                                    <span class="fw-bold">Unlimited</span> signees
                                </span>
                            </div>
                            <div>
                                <fa icon="check" size="lg" class="me-3"/>Send <span class="fw-bold">invitations</span>
                            </div>
                        </div>
                        
                    </div>
                    <div v-if="!isPaymentSuccess && route.params.packageId === 'team'" class="col-12 col-lg-6 mb-3 text-center">
                        <div class="mb-3">Click this button will redirect you to payment page</div>
                        <div class="text-center">
                            <button class="btn btn-secondary" @click="makePayment">
                                <fa icon="dollar-sign" class="me-2" />Make payment
                            </button>
                        </div>
                    </div>
                </div>
            
                <div v-if="upgradeStep === 4" class="row">
                    <div class="col-12 mb-4">
                        <UpgradeStep step="3" />
                    </div>
                    <div v-if="isPaymentSuccess" class="col-12">
                        <div class="fw-bold mb-2">Payment Success</div>
                        <div>You have upgraded to {{route.params.packageId}} package successfully.</div>
                    </div>

                    <div v-if="!isPaymentSuccess" class="col-12">
                        <div class="fw-bold mb-2">Payment Failed</div>
                        <div>Failed to upgrade to {{route.params.packageId}} package. <br />Kindly contact our support at <u>support@signon.my</u> for further assistance.</div>
                    </div>

                </div>
            </div>
            
        </div>
    </div>

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAvatar"
        tabindex="-1"
        aria-labelledby="UplAvatar"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Upload Avatar</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <!-- <div class="col-12">New uploaded avatar will replace your current avatar</div> -->
                        <div class="col-12">
                            <file-pond
                                name="file"
                                ref="pondA"
                                label-idle="Drag-n-drop image"
                                v-bind:allow-multiple="false"
                                accepted-file-types="image/jpeg, image/png, image/gif,"
                                :server="avatarConfig" 
                                :files="myAvatars"
                                storeAsFile="true"
                                @init="handleAvatarPondInit"
                                @error="uplAvatarError"
                                @processfile="onprocessAvatar"
                                allowRevert="true"
                                allowRemove="false"
                            />
                            <div class="text-center small text-grey">Min. 120 x 120 pixels <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal --> 
    <div class="modal fade" id="mdlUpdProfile" tabindex="-1" aria-labelledby="UpdProfile" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Email Verification Required</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-4">In order to use this feature, you must first verify your email address. An email has been sent to {{getUserId}}. Please look for this email and click the activation link to continue.</div>
                    <div><hr /></div>
                    <div class="mb-2">If you entered your email address incorrectly, please update your primary email address below</div>
                    <div class="mb-2"><input type="email" v-model="newEmail" :placeholder="getUserId" class="form-control" /></div>
                    <div class="d-grid">
                        <button class="btn btn-secondary btn-block" :disabled="newEmail.length === 0" @click="resendActivation" data-bs-dismiss="modal">Resend Activation Email</button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- Modal End-->
</template>

<script>
import { ref, inject, onMounted, computed } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import funcs from '@/functions/function'
import UpgradeStep from '@/components/UpgradeStep.vue'

// Image Uploader for avatar
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
    name: 'ManageAccount',
    components: {
        TopNavigation, Alert, Popper, FilePond, Tooltip, UpgradeStep
    },
    setup () {
        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const store = useStore()
        const { getUserId, getUserStatus, getUserIsVerified } = useStore()

        const email = ref(null)
        const newEmail = ref('')
        const firstName = ref(null)
        const lastName = ref(null)
        const displayName = ref(null)
        const newDisplayName = ref(null)
        const title = ref('Mr')
        const gender = ref('M')
        const gmt = ref('+8:00')
        const avatar = ref('')
        const avatarUrl = ref('')
        const dateCreate = ref('')
        const dateLastLogin = ref('')
        const plan = ref('')
        const gmtOpt = ref(null)
        const titleOpt = ref(null)
        const genderOpt = [
            { label: 'Female', value: 'F'},
            { label: 'Male', value: 'M'}
        ]

        const oldPassword = ref(null)
        const password = ref(null)
        const password2 = ref(null)

        const arrSig = ref([])
        const arrIni = ref([])
        const showMoreSig = ref(false)
        const showMoreIni = ref(false)

        const selectBillTab = ref(false)
        const upgradeStep = ref(0)
        const upgradePassword = ref(null)
        const upgradeEmail = ref(store.getUserId)
        const upgradeTac = ref(null)
        const isValidPassword = ref(false) // in upgradeStep = 1
        const isValidTac = ref(false) // in upgradeStep = 2
        const isPaymentSuccess = ref(false) // in upgradeStep = 3

        const avatarConfig = ref({ 
            "url": "https://signon.my:3002/user/avatar/upload", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 
            
        const kycConfig = ref({
            "url": "https://signon.my:3002/user/kyc/upload", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 

        const signatureConfig = ref({ 
            "url": "https://signon.my:3002/user/signature/signature", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            } 
        })

        const initialConfig = ref({ 
            "url": "https://signon.my:3002/user/signature/initial", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            } 
        })

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const getProfile = () => {
            axios.get('/user/profile')
                .then((res) => {
                    console.info('getProfile', JSON.stringify(res.data.data))
                    if (res.data.status === 1) {
                        email.value = res.data.data[0].userId
                        firstName.value = res.data.data[0].firstName
                        lastName.value = res.data.data[0].lastName
                        displayName.value = res.data.data[0].displayName
                        title.value = res.data.data[0].title
                        gender.value = res.data.data[0].gender
                        gmt.value = res.data.data[0].gmt
                        avatar.value = res.data.data[0].avatar

                        avatarUrl.value = res.data.data[0].avatar.url
                        newDisplayName.value = res.data.data[0].displayName
                        // avatar.value.url = 'https://upload.wikimedia.org/wikipedia/en/d/d6/Avatar_%282009_film%29_poster.jpg'
                        dateCreate.value = res.data.data[0].dateCreate
                        dateLastLogin.value = res.data.data[0].dateLastLogin
                        plan.value = res.data.data[0].package
                    }
                })
                .catch((error) => {

                    console.log('getProfile | ERR', JSON.stringify(error))
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })

                })
        }

        const updProfile = () => {

            if (displayName.value === null || displayName.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Display name is required."
                })

            } else {

                const p = {
                    firstName: firstName.value,
                    lastName: lastName.value,
                    displayName: displayName.value,
                    title: title.value,
                    gender: gender.value,
                    gmt: gmt.value
                }

                axios.put('/user/profile', p)
                    .then((res) => {
                        console.info('updProfile', JSON.stringify(res))
                        if (res.data.status === 1) {

                            newDisplayName.value = computed({
                                get: () => store.getDisplayName.value,
                                set: store.updDisplayName(displayName.value),
                            })
                            newDisplayName.value = store.getDisplayName.value 

                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Personal information is updated."
                            })
                        }
                    })
                    .catch((error) => {

                        console.log('updProfile | ERR', JSON.stringify(error))
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.convError(error)
                        })
                        
                    })
            }
        }

        const updPassword = () => {

            if (password.value !== password2.value) {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Password and reconfirm password do not match. '
                })

            } else {
                const p = {
                    currentPassword: oldPassword.value,
                    password: password.value
                }

                console.info('updProfile p', JSON.stringify(p))

                axios.put('/user/password', p)
                    .then((res) => {
                        console.info('updPassword', JSON.stringify(res))
                        if (res.data.status === 1) {
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Password is changed."
                            })
                        }
                    })
                    .catch((error) => {

                        console.log('updPassword | ERR', JSON.stringify(error))
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.convError(error)
                        })
                    })

            }
        }

        const getGmtOpt = () => {
            let curDate = new Date() + ''
            let tmp = curDate.split('GMT')
            let tmp1 = tmp[1].split(' ')
            let tmpGmt = tmp1[0].replace(' ', '')
            gmt.value = tmpGmt.substr(0, 3) + ':' + tmpGmt.substr(3, 2)

            axios.get('/reference/gmt')
                .then((res) => {
                    if (res.data.status === 1) {
                        gmtOpt.value = res.data.data
                    }
                })
                .catch((error) => {
                    console.log('getGmt | ERR', JSON.stringify(error))
                })
        }

        const getTitleOpt = () => {
            // Title list
            axios.get('/reference/title')
            .then((res) => {
                if (res.data.status === 1) {
                    titleOpt.value = res.data.data
                }
            })
            .catch((error) => {

                console.log('delDoc | ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        }

        const getKyc = (error, file) => {
            console.info('addfile: err', error)
            console.info('addfile: file', file)
            console.info('addfile: file.filename', file.filename)
            console.info('addfile: file.fileSize', file.fileSize)
            console.info('addfile: file.file', file.file)
            console.info('addfile: file.source', file.source)
            console.info('addfile: file.status', file.status)
            console.info('addfile: file.transferId', file.transferId)
        }

        const uplKycError = (error) => {
            console.info('uplKycError', error)
        }

        const uplAvatarError = (error) => {
            console.info('uplAvatarError', error)
        }

        const onprocessAvatar = (err, file) => {
            // console.info('processfile: err', err, 'file:' , file)
            if (err === null) {
                getProfile()
            }
        }

        const updAvatarStore = () => {
            avatarUrl.value = computed({
                get: () => store.getAvatar.value,
                set: store.updAvatar(avatar.value.url),
            })
            avatarUrl.value = store.getAvatar.value 
        }

        const resendActivation = () => {
            const p = {
                username: newEmail.value,
                frontendUrl: 'https://signon.my/dashboard'
            }

            console.info('resendActivation', JSON.stringify(p))

            axios.post('/auth/signup/resend', p)
                .then((res) => {
                    if (res.data.status === 1) {
                        alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "An activation email has sent to you, please click the link to activate it."
                            })
                    }
                })
                .catch((error) => {
                    console.log('resendActivation | ERR', JSON.stringify(error))
                })
        }

        const uplSignatureError = (error) => {
            console.info('uplSignatureError', error)
        }

        const uplInitialError = (error) => {
            console.info('uplInitialError', error)
        }

        const onprocessSignature = (err, file) => {
            if (err === null) {
                getHistoryList('signature')
            }
        }
        const onprocessInitial = (err, file) => {
            // console.info('processfile: err', err)
            // console.info('processfile: file', file)
            if (err === null) {
                getHistoryList('initial')
            }
        }

        const delSignature = (id, opt) => {
            console.info('del', id, opt)
            axios.delete( '/user/signature/' + id)
                .then((res) => {
                    if (res.data.status === 1) {

                        getHistoryList(opt)
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Signature is removed."
                        })
                    } 
                })
                .catch((error) => {
                    console.log('delSignature | FAILURE', JSON.stringify(error))
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                })
        }

        const getHistoryList = (listOpt) => {
            axios.get( '/user/signature/list/' + listOpt)
            .then((res) => {
                if (res.data.status === 1) {
                    if (listOpt === 'initial') {
                        arrIni.value = res.data.data
                    } else {
                        arrSig.value = res.data.data
                    }
                } 
            })
            .catch((error) => {
                console.log('getHistoryList | FAILURE', JSON.stringify(error))
            })
        }

        const validatePassword = () => {
            setTimeout(() => {
                isValidPassword.value = true
            }, 1000)
        }

        const sendUpgradeEmail = () => {
            // send email
            upgradeStep.value = 2
        }

        const verifyUpgradeTac = () => {
            setTimeout(() => {
                isValidTac.value = true
                upgradeStep.value = 3
            }, 1000)
        }

        const makePayment = () => {
            router.push({ path: '/auth/preference/upgrade/' + route.params.packageId + '/payment'})
        }

        onMounted(() => {
            console.info('onMounted')
            func.clearPrevLocalStorage()

            if (route.params.packageId === 'personal' || route.params.packageId === 'team') {
                upgradeStep.value = 1
            }

            // Payment status page returned from Online Banking
            if (route.path === '/auth/preference/upgrade/personal/payment' || route.path === '/auth/preference/upgrade/team/payment'){
                upgradeStep.value = 4
                isPaymentSuccess.value = true

                setTimeout(() => {
                    router.push({ path: '/auth/preference/bill'})
                }, 2000)
            }     
            
            // Redirect to 'Billing & Subscription' tab after payment made
            if (route.path === '/auth/preference/bill'){
                selectBillTab.value = true
            }     
            
            
            getProfile()
            getGmtOpt()
            getTitleOpt()

            getHistoryList('initial')
            getHistoryList('signature')

            let objMdl1 = document.querySelector('#mdlAvatar')

            objMdl1.addEventListener("hide.bs.modal", function (event) {
                console.info('trigger modal close')
                avatarUrl.value = computed({
                    get: () => store.getAvatar.value,
                    set: store.updAvatar(avatar.value.url),
                })
                avatarUrl.value = store.getAvatar.value 
            })
            
        })

        return { 
            route, router, alert, closeAlert, getUserStatus, getUserIsVerified,
            // getUserId, getDisplayName, getAvatar,
            email, firstName, lastName, displayName, title, gender, gmt, avatar, gmtOpt, titleOpt, genderOpt,
            oldPassword, password, password2, avatarUrl, newDisplayName, 
            avatarConfig, uplAvatarError, onprocessAvatar, kycConfig, uplKycError, getKyc, 
            updProfile, updPassword, func, newEmail, resendActivation, getUserId, 
            signatureConfig, initialConfig, arrSig, arrIni, showMoreSig, showMoreIni, delSignature, onprocessSignature, onprocessInitial, uplSignatureError, uplInitialError, 
            dateCreate, dateLastLogin, plan, upgradeStep, upgradePassword, upgradeEmail, validatePassword, isValidPassword, sendUpgradeEmail, 
            verifyUpgradeTac, isValidTac, makePayment, isPaymentSuccess, selectBillTab,
        }
    },
    data () {
        return {
            myAvatars: [],
            myKyc: [],
            mySignatures: [],
            myInitials: []
        }
    },
    handleAvatarPondInit (){
        // console.info('FilePond has initialized')
        // example of instance method call on pond reference
        this.$refs.pondA.getFiles()
    },
    handleKycUploadInit () {
        this.$refs.pondKyc.getFiles()
    },
    handleSignaturePondInit (){
        this.$refs.pondS.getFiles()
    },
    handleInitialPondInit (){
        this.$refs.pondI.getFiles()
    }
}
</script>

<style>
body {
  overflow: scroll ;
}

.customLink {
    z-index: 10000; 
    padding-top: 70px; 
    opacity: 0.6;
}

.customLink > svg {
    visibility: hidden;
    width: 122px;
}

.customLink:hover > svg {
    visibility: visible;
}

.customLink:hover {
    background-color: white;
}

#lblCertificate {
    font-size: 14px;
    color: white;
    background-color: #333333;
    padding: 10px;
    border-radius: 5px;
    visibility: hidden;
}

#certificate:hover #lblCertificate {
    visibility: visible;
}

.packageBox {
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 25px;
}

@media (max-width: 575.98px) {
  .packageBox {
    width: 98%;
    margin-bottom: 25px;
    border-radius: 10px;
    padding: 25px;
  }
}

.filter-pill-border {
    /* border: 1px solid var(--accent-color); */
    border: 1px solid var(--bs-primary);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.lblSelected {
    font-weight: bold;
    color: var(--bs-primary);
}
</style>