<template>
    <span class="filter-pill-border px-3">
        <span :class="upgradeStep === 1 ? 'lblSelected' : ''">Authority</span>
        <span><fa icon="angle-right" class="mx-2 text-grey"/></span>
        <span :class="upgradeStep === 2 ? 'lblSelected' : ''">TAC</span>
        <span ><fa icon="angle-right" class="mx-2 text-grey"/></span>
        <span :class="upgradeStep === 3 ? 'lblSelected' : ''">Payment</span>
    </span>
</template>

<script>
import { ref } from 'vue'

export default {
    name: 'UpgradeStep',
    props: ['step'],
    setup (props) {

        const upgradeStep = ref(props.step)

        return { upgradeStep }
    }
}
</script>

<style>
.lblSelected {
    font-weight: bold;
    color: var(--bs-primary);
}
</style>